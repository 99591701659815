@import 'src/styles/utils';

.container {
    position: fixed;
    z-index: 999;
    right: 12px;
    bottom: 12px;
}

.toast {
    width: 600px;

    :global .close:focus {
        outline: 1px dotted $color-tarmac-light-80;
    }
}
