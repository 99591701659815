@import 'src/styles/utils';

.container {
    align-items: center;
    background-color: $color-tarmac-light-10;
    color: $color-white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.header {
    color: $color-white;
}

.text {
    margin: 0.5rem 0;
}
